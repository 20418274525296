import React from 'react';

function MarkdownTextComponent(input) {
    const pattern = /\[([^\]]+)\]\(([^)]+)\)/g;

    let lastIndex = 0;
    let match;
    const output = [];

    while ((match = pattern.exec(input)) !== null) {
        const [fullMatch, linkText, linkUrl] = match;

        // add plain text before the match
        if (match.index > lastIndex) {
            output.push(input.substring(lastIndex, match.index));
        }

        // add link
        output.push(<a key={linkUrl} href={linkUrl}>{linkText}</a>);

        lastIndex = pattern.lastIndex;
    }

    // add remaining plain text after the last match
    if (lastIndex < input.length) {
        output.push(input.substring(lastIndex));
    }

    return output;
}

const MarkdownText = ({ text }) =>    <div>
{text.split('\n').map((line, i) => (
    <p key={i}>{MarkdownTextComponent(line)}</p>
))}
</div>;

export default MarkdownText;
