import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import parse from 'html-react-parser';

import css from './ListingPage.module.css';
import MarkdownText from './MarkdownText';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description , address, publicData, intl} = props;
  const { building } = publicData?.location || {}
  const { unit } = publicData || {};
  const unitLabel = intl.formatMessage({
    id: 'ListingPage.unitLabel',
  });
  const parsedDescription = !!description ? parse(description) : '';
  const unitNum =
    typeof unit === 'string' && unit.length > 0
      ? `. ${unitLabel} ${unit}`
      : typeof building === 'string' && building.length > 0
      ? `. ${unitLabel} ${building}`
      : null;

  return description ? (
    <div className={css.sectionDescription}>

    <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.addressTitle" />
      </h2>
      <p className={css.description}>
         {richText(address, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
          linkify: true,
        })}
        {unitNum}
      </p>
   
      
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <div>{typeof(parsedDescription) === 'string' ? <MarkdownText text={description} /> : parsedDescription}</div>
      <div></div>
   
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;